import React from 'react'
import './style.less';
import {Row, Col, Form, Input, Button} from 'antd';
import Link from "../Link";

const { TextArea } = Input;

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.validateFormAndSend = this.validateFormAndSend.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.state = {
            complete: false,
            status: false,
            errors: []
        };
    }

    validateFormAndSend(e){
        e.preventDefault();

        // Validate the form
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.sendMessage(values);
            }
        });
    }

    sendMessage(values){
        return fetch('https://api.brew-systems.co.uk/contact-storyman/', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'first_name' : values.first_name,
                'last_name' : values.last_name,
                'email' : values.email,
                'message' : values.message,
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.success === true){
                // Clear and hide the form
                this.setState({ complete: true, status: 'success'});
            } else {
                // Display errors
                this.setState({ status: 'error', errors: res.errors});
            }
        }).catch(err => err);
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        if (this.state.complete === false) {
            return (
                <Row className="contact-us">
                    <Col xs={{span: 24}}>
                        <h2 className="big">Get in touch</h2>
                    </Col>
                    <Col xs={{span: 24}} md={{span:20, offset: 2}}>
                        <Form layout="inline" className="contact-form">
                            <Row>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <p className="desc">Want broadcast-quality video content that tells your story? We're just a message away...</p>
                                    <p className="email">hello@storyman.co</p>
                                    <p className="phone">+44 (0) 207 060 4517</p>
                                    <p className="address">Huckletree West, MediaWorks,<br/>
                                        191 Wood Lane, White City Place,<br/>
                                        London, W12 7TU<br/>
                                        <Link to="/" className="arrow-link">Google Maps</Link></p>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <Form.Item label="First name">
                                        {getFieldDecorator('first_name', {
                                            rules: [{
                                                required: false,
                                                message: 'Please enter your first name'
                                            }],
                                            initialValue: ''
                                        })(
                                            <Input placeholder=""/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Last name">
                                        {getFieldDecorator('last_name', {
                                            rules: [{
                                                required: false,
                                                message: 'Please enter your first name'
                                            }],
                                            initialValue: ''
                                        })(
                                            <Input placeholder=""/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Email address">
                                        {getFieldDecorator('email', {
                                            rules: [{type: "email", message: "Please enter a valid email address"}, {
                                                required: true,
                                                message: 'Please enter an email address'
                                            }],
                                            initialValue: ''
                                        })(
                                            <Input placeholder=""/>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Message">
                                        {getFieldDecorator('message', {
                                            rules: [{required: true, message: 'Please provide an overview of the campaign'}],
                                            initialValue: ''
                                        })(
                                            <TextArea rows={4}/>
                                        )}
                                    </Form.Item>

                                    <Button className="btn-primary" onClick={this.validateFormAndSend}>Send</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="contact-us">
                    <Col xs={{span: 24}}>
                        <h2 className="big">Get in touch</h2>
                    </Col>
                    <Col xs={{span: 24}} md={{span:20, offset: 2}}>
                        <Form layout="inline" className="contact-form">
                            <Row>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <p className="desc">Want broadcast-quality video content that tells your story? We're just a message away...</p>
                                    <p className="email">hello@storyman.co</p>
                                    <p className="phone">+44 (0) 207 060 4517</p>
                                    <p className="address">Huckletree West, MediaWorks,<br/>
                                        191 Wood Lane, White City Place,<br/>
                                        London, W12 7TU<br/>
                                        <Link to="/" className="arrow-link">Google Maps</Link></p>
                                </Col>
                                <Col xs={{span: 24}} md={{span: 12}}>
                                    <h2>Thanks!</h2>
                                    <p>Thanks for getting in touch.</p>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            );
        }
    }
}

export default Form.create()(ContactUs);